<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">CLIENT</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Find Client" />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" :to="{ name: 'clients-create' }">
            <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" /> Client Account</span>
          </b-button>
        </div>
      </div>

      <b-table ref="refClientListTable" class="position-relative" :items="fetchClients" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(first_name)="data">
          <div>
            {{ data.item.first_name + " " + data.item.last_name }}
          </div>
        </template>
        <template #cell(gender_id)="data">
          <div>
            {{ data.item.gender.name }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <!-- v-if="ability.can('view', 'client_account')" -->
            <b-dropdown-item :to="{ name: 'clients-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'clients-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalClients" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BFormGroup,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useClientsList from "./useClientsList";
import clientStoreModule from "../clientStoreModule";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    triggerResetPasswordModal(item) {
      console.log("triggerResetPasswordModal", item);
      this.clientInfo.id = item.id;
      this.clientInfo.name = item.name;
      this.clientInfo.password = "";
      this.clientInfo.password_confirmation = "";
      this.$bvModal.show("modal-reset-client-password");
    },
  },
  data() {
    return {
      clientInfo: { id: 0, password: "", password_confirmation: "" },
      remarks: [],
      newRemark: {
        client_id: null,
        remarks: null,
      },
      show: false,
      editRemarkBoolean: false,
    };
  },
  methods: {
    addRemarksShow(client_id) {
      this.newRemark.client_id = client_id;
      this.$bvModal.show("add-remarks");
    },
    addRemarksHide() {
      this.$bvModal.hide("add-remarks");
    },
    deleteRemark(id) {
      this.show = true;
      store
        .dispatch("app-client/deleteRemark", { id: id })
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.$bvModal.hide("remarks-modal");
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    confirmEdit(remark) {
      this.show = true;
      this.editRemarkBoolean = false;
      store
        .dispatch("app-client/addNewRemark", remark)
        .then((response) => {
          this.show = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
          this.editRemarkBoolean = false;
        })
        .catch((error) => {
          this.show = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    editRemark() {
      this.editRemarkBoolean = true;
    },
    addNewRemarks() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-client/addNewRemark", this.newRemark)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.addRemarksHide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    openModel(remark) {
      this.remarks = remark;
      this.$bvModal.show("remarks-modal");
    },
    hideModal() {
      console.log("remarks clicked");
      this.$bvModal.hide("remarks-modal");
    },
    onBtnExport() {
      console.log("client export");
      store.dispatch("app-client/fetchClientsExport").then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Clients Export List`);
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    onBtnExportSimple() {
      console.log("client export");
      store.dispatch("app-client/fetchClientsExportSimple").then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Clients Export List`);
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-client";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      ability,
      statusFilter,
    } = useClientsList();

    return {
      // Sidebar
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      ability,
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.cursor {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
